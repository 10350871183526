import arrowdown from "../assets/images/arrowdown.svg"
import arrowright from "../assets/images/arrowrightacc.svg"

const Accordian = ({ data, show, setShow }) => {
    return (
      <div className="accordian-container">
        <div
          className="accordian-container-header"
          onClick={() => setShow(data?.id)}
        >
          
          <h1>{data?.title}</h1>

          <div onClick={() => setShow(data?.id)}>
            {show == data?.id ? (
            //   <ImMinus
            //     size={25}
            //     style={{ color: "#E84D19" }}
            //     onClick={() => setShow(0)}
            //   />
            <img onClick={() => setShow(0)} src={arrowdown} alt="arrowdown" />
            ) : (
                <img  src={arrowright} alt="arrowright"/>
            )}
          </div>
        </div>
        {show == data?.id ? (
          <div className="accordian-container-content" data-aos="fade-in">
            <p>{data?.content}</p>
          </div>
        ) : null}
      </div>
    );
  };
  
  export default Accordian;