export const utilitiesData = [
  {
    id:1,
    content: "Unique and hand drawn NFTs all about the World of Sports"
  },
  {
    id:2,
    content: "Excellent network in the professional sports segment"
  },
  {
    id:3,
    content: "Online Sport Community to chat and network with like-minded people"
  },
  {
    id:4,
    content: "Provide sporting opportunities to young children who would not otherwise have access"
  },
  {
    id:5,
    content: "Sports Charity"
  },
  {
    id:6,
    content: "VIP-Tickets for international Top soccer games"
  },
  {
    id:7,
    content: "Building an own Trading Card Platform"
  },
  {
    id:8,
    content: "Soccer betting/tipping competitions with prices for holders"
  },
  {
    id:9,
    content: "World of Sports Merchandise - Soccer shirts, hoodies etc. with their own NFT on them"
  },
  {
    id:10,
    content: "CoFinance Sports Fields/Teams/Colleges"
  },
  {
    id:11,
    content: "Sponsor young sports stars - offer young soccer stars a Sponsorship to college"
  },
  {
    id:12,
    content: "Sponsor sports teams"
  },
  {
    id:13, 
    content: "Buy a portion of a pro football team ! (Community driven)"
  },
 
]


export const benefitsData = [
    {
    id:1,
    content:"Be Part Of Giving Back To Sporting Youth And Providing Sports"},
    {
    id:2,
    content:"Own A Part Of A Real-World Football Team Or Helped Sponsor Equipment For Real Sports Teams/Club/Colleges/Players"},
    {
    id:3,
    content:"Earn Real-Life Rewards Like Money Or ETH, Sports Tickets, Merchandise For Participating In Our Community Events"},
    {
    id:4,
    content:"Random Soccer Games, Champions League, Euro Tickets/Flights All In For A Couple Of Holders"},
    {
    id:5,
    content:"Personalized Soccer Merchandise For Holders"},
    {
    id:6,
    content:"Together We Are The World Of Sports!"},
]

export const accordianData = [
  {
    id: 1,
    title: "How can i join “WORLD OF SPORTS CLUB”?",
    content:
      "Join our discord to be a part of our club",
  },
  {
    id: 2,
    title: "How Do i purchase an NFT from WORLD OF SPORTS ?",
    content:
    "You will be able to mint an original WORLD OF SPORTS NFT through our website-minting.",
  },
  {
    id: 3,
    title: "Will there be a PRE-SALE?",
    content:
    "Yes ! Club members will have the opportunity to participate in the PRE-SALE.",
  },
  {
    id: 4,
    title: "What BLOCKCHAIN will the wORLD OF SPORTS NFTs live on ?",
    content:
    "ERC-721 (ETH) ETHEREUM BLOCKCHAIN",
  },
  {
    id: 5,
    title: "Where will be able to view my NFTs ?",
    content:
    "Once you have minted a WORLD OF SPORTS NFT,you will be able to see it by connecting your crypto wallet (Metamask as example) to OpenSea.",
  },
  
];