import React from "react";

const PrivacyPolicy = () => {
  const ppData = [
    "Welcome",
    "Personal Information Collection",
    "Personal Information Use",
    "Personal Information Use",
    "Cookies and Web Beacons",
    "Children",
    "Links to Other sites",
    "Security",
    "Your Data Privacy rights under the GDPR?",
    "Changes to our Privacy Policy",
    " Contact Us",
  ];
  return (
    <div className="ppcont">
      <div className="ppcont-wrapper">
        <div className="ppcont-wrapper-topheading">
          <h1>WORLDOFSPORTS.NFT PRIVACY POLICY</h1>
        </div>
        <div className="ppcont-wrapper-contentparent">
          <div className="ppcont-wrapper-contentparent-headingdiv">
            <h2>Table of Content</h2>
          </div>
          <div className="ppcont-wrapper-contentparent-contentdiv">
            {ppData?.map((item, index) => (
              <div className="ppcont-wrapper-contentparent-contentdiv-mapdata">
                <span>{index + 1 + "."}</span>
                <p>{item}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="ppcont-wrapper-cdata">
          <div className="ppcont-wrapper-cdata-heading">
            <h5>PLEASE READ THIS PRIVACY STATEMENT CAREFULL</h5>
          </div>
          <div className="ppcont-wrapper-cdata-headingdata">
            <h4>1. Welcome</h4>
            <p>
              <strong>Worldofsports.NFT</strong> respects the privacy of every
              (creators), and complies with all Applicable laws for the
              protection of user privacy, including, without limitation, all
              required Directives on user protection regulations of where you
              are accesing the site. Nevertheless, all personal data is duly
              encrypted and sited in a secured servers.
            </p>

            <p>
              The <stong>Worldofsports.NFT</stong> site is the data controller of
              your Personal Data. When this Privacy Policy mentions “we,” “us,”
              or “our,” it refers to the relevant <strong>Worldofsports</strong>
              entity accessible at 
              <strong> https://www.worldofsports-nft.com</strong>. which is
              providing the website and NFT collections directly to you .
            </p>

            <p>
              By minting the <strong>Worldofsports NFT</strong>, you hereby
              consent to our Privacy Policy and agree to its terms herein. If
              you are not comfortable with any aspect of this Privacy policy.
              You should immediately leave your access to the Site.{" "}
            </p>
          </div>
          <div className="ppcont-wrapper-cdata-headingdata">
            <h4>2. Personal Information Collection</h4>
            <p>
              For the provision of the <strong>Worldofsports </strong>{" "}
              Collections, we may collect and process the following personal
              information about you, but only with your consent;
            </p>
            <div className="ppcont-wrapper-cdata-headingdata-bulletpoints">
              <span>●</span>
              <h6>
                {" "}
                <strong>Wallet Information:</strong> To hold and own a
                 <strong> Worldofsports</strong> NFT, you will be required to
                connect a Metawallet wallet, during this process, we will
                collect your Metawallet wallet address, to claim and hold your 
                 <strong> Worldofsports</strong> NFT;
              </h6>
            </div>
            <div className="ppcont-wrapper-cdata-headingdata-bulletpoints">
              <span>●</span>
              <h6>
                {" "}
                <strong>Community Information:</strong> Along the line, we
                collect information relating to your participation in the
                <strong> Worldofsports</strong> community, including the
                Worldofsports whitelists, and pre-sale you participated, and any
                information you share through the <strong>Worldofsports</strong>{" "}
                community.
              </h6>
            </div>
            <div className="ppcont-wrapper-cdata-headingdata-bulletpoints">
              <span>●</span>
              <h6>
                <strong>Communication Information:</strong> We may collect
                information when you reach out to us with questions or
                complaints; sometimes we collect information from you when you
                voluntarily fill out our questionnaires, surveys or requests for
                market research seeking your opinion and feedback
              </h6>
            </div>
            <div className="ppcont-wrapper-cdata-headingdata-bulletpoints">
              <span>●</span>
              <h6>
                {" "}
                <strong>Divice Identifiers": </strong>Information relating to
                your Computer or mobile Devices – this information includes: The
                type of mobile, tablet, or PC, browser you use when you access
                the site with your e.g. manufacturer, MAC address, model,
                operating system, screen dimensions, OS version, Virtual Machine
                name, version and IP Address).
              </h6>
            </div>
            <p>
              By using or accessing the <strong>Worldofsports</strong> Site or
              through any other means, you acknowledge that you have read this
              Policy, understand it, agree to its terms, and authorize{" "}
              <strong>Worldofsports</strong> to collect, use and disclose
              information pursuant to this Policy.
            </p>
          </div>
          <div className="ppcont-wrapper-cdata-headingdata">
            <h4>3. Personal Information Use</h4>
            <p>
              We use the information we collect in various ways, including to:
            </p>
            <div className="ppcont-wrapper-cdata-headingdata-bulletpoints">
              <span>●</span>
              <h6>
                Provide, operate, and maintain our NFT website, events, rewards
                etc.
              </h6>
            </div>
            <div className="ppcont-wrapper-cdata-headingdata-bulletpoints">
              <span>●</span>
              <h6>
                Process your ownership of the <strong>Worldofsports.NFT</strong>
                , via the Ethereum Network;
              </h6>
            </div>
            <div className="ppcont-wrapper-cdata-headingdata-bulletpoints">
              <span>●</span>
              <h6>
                Understand and analyze how you interact with our products;
              </h6>
            </div>
            <div className="ppcont-wrapper-cdata-headingdata-bulletpoints">
              <span>●</span>
              <h6>
                Develop new products, services, features, and functionality
              </h6>
            </div>
            <div className="ppcont-wrapper-cdata-headingdata-bulletpoints">
              <span>●</span>
              <h6>
                Comply with applicable laws, lawful requests, and legal process,
                such as to respond to subpoenas or requests from government
                authorities;
              </h6>
            </div>
            <div className="ppcont-wrapper-cdata-headingdata-bulletpoints">
              <span>●</span>
              <h6>
                Protect us, you or others' rights, privacy, safety or property
                (including by making and defending legal claims);
              </h6>
            </div>
          </div>
          <div className="ppcont-wrapper-cdata-headingdata">
            <h4>4. Personal Information Sharing</h4>
            <p>
              We may share some categories of personal information with your
              consent, and as required by applicable law, with the following
              third parties:
            </p>
            <div className="ppcont-wrapper-cdata-headingdata-bulletpoints">
              <span>●</span>
              <h6>
                <strong>Service Providers:</strong> Our Service providers assist
                us in meeting our day to day operations needs including to
                perform certain services, completing your participation in the
                <strong> Worldofsports NFT</strong> minting, whitelists and
                pre-sale, e.g. hosting services, cloud services, email
                communication software, etc. Pursuant to our instructions, these
                parties will not and never sell your personal information other
                than the instructions given to them from us;
              </h6>
            </div>
            <div className="ppcont-wrapper-cdata-headingdata-bulletpoints">
              <span>●</span>
              <h6>
                <strong>Professional Advisors:</strong> Sometimes when we have
                some court orders, we may be required to share some personal
                information with our authourized professional advisors e.g.
                lawyers and accountants where doing so is necessary to
                facilitate the services they render on our behalf;
              </h6>
            </div>
            <div className="ppcont-wrapper-cdata-headingdata-bulletpoints">
              <span>●</span>
              <h6>
                <strong>Legal Requirements:</strong> Sometimes we may be
                directed by a court order to disclose your personal information
                for the Compliance and Protection purposes described above;
              </h6>
            </div>
            <p>
              However, you reserve the right to stop the sharing of your
              personal information as directed by the GDPR, please send us a
              message if you don’t want your personal information be shared with
              our authourized third party.
            </p>
          </div>
          <div className="ppcont-wrapper-cdata-headingdata">
            <h4>5. Cookies and Web Beacons</h4>
            <p>
              Like any other website,<strong> Worldofsports</strong> uses
              'cookies'. These cookies are used to store information including
              visitors' preferences, and the pages on the website that the
              visitor accessed or visited. The information is used to optimize
              the users' experience by customizing our web page content based on
              visitors'browser type and/or other information.
            </p>

            <div className="ppcont-wrapper-cdata-headingdata-centerheading">
              <h3>Disabling or blocking cookies? </h3>
            </div>

            <p>
              If you'd like to delete cookies or instruct your web browser to
              delete or refuse cookies, please visit your web browser's help
              pages.
            </p>

            <p>
              if you delete cookies or refuse to accept them, you might not be
              able to use all of the features we offer, you may not be able to
              store your preferences, and some of our pages might not display
              correctly.{" "}
            </p>
          </div>
          <div className="ppcont-wrapper-cdata-headingdata">
            <h4>6. Children</h4>
            <p>
              The <strong>Worldofsports</strong> NFT is not directed to children
              who are under the age of 18. We comly with the all Children
              Privacy Law. For this reason, we do not knowingly collect personal
              information from children under the age of 18. If you believe that
              a child under the age of 16 provided personal information to us
              please contact us and we will delete that information immediately.
            </p>
          </div>
          <div className="ppcont-wrapper-cdata-headingdata">
            <h4>7. Links To Other Websites</h4>
            <p>
              The <strong>Worldofsports</strong> site accessible at
              <strong> https://www.worldofsports-nft.com</strong>. may contain
              links to other site not owened or operated by us, (“Third Party
              Sites”). The information that you share with such Third Party
              Sites will be governed by the specific terms of service and
              privacy policy of the Third Party Site you accessed. The{" "}
              <strong>Worldofsports</strong> NFT do not endorse or reviewed
              these sites. Please contact the Third Party Sites directly for
              information on their privacy practices and terms.
            </p>
          </div>
          <div className="ppcont-wrapper-cdata-headingdata">
            <h4>8. Security</h4>
            <p>
              We have put in place numerous technical, organizational and
              physical safeguards measures designed to protect the personal
              information of users. However, no online security measures are
              guaranteed. You agree that your use of The{" "}
              <strong>Worldofsports NFT</strong> site is at your own risk.
            </p>
          </div>

          <div className="ppcont-wrapper-cdata-headingdata">
            <h4>9. Your Data privacy rights under the GDPR?</h4>
            <p>
              Under the General Data Protection Regulation, you have the right
              to
            </p>
            <div className="ppcont-wrapper-cdata-headingdata-bulletpoints">
              <span>●</span>
              <h6>
                {" "}
                <strong>Access</strong>, (i.e. you may ask us to send you a copy
                of the data about you that We collected);
              </h6>
            </div>
            <div className="ppcont-wrapper-cdata-headingdata-bulletpoints">
              <span>●</span>
              <h6>
                <strong>Rectify</strong>, (i.e. you may ask us to correct the
                outdated or false data, or complete the data which is missing or
                incomplete)
              </h6>
            </div>
            <div className="ppcont-wrapper-cdata-headingdata-bulletpoints">
              <span>●</span>
              <h6>
                <strong>Port and</strong> (i.e. you may ask us to transfer a
                copy of your data to another organization or to you provided
                that the circumstances comply with the GDPR).
              </h6>
            </div>
            <div className="ppcont-wrapper-cdata-headingdata-bulletpoints">
              <span>●</span>
              <h6>
                {" "}
                <strong>Erase your data</strong> (i.e. you may ask us to delete
                the personal data We retain, provided that the GDPR allows you
                to do so);
              </h6>
            </div>
            <div className="ppcont-wrapper-cdata-headingdata-bulletpoints">
              <span>●</span>
              <h6>
                {" "}
                <strong>Object</strong> to and (if the GDPR reserves your right
                to object as to certain kinds of processing or in certain
                circumstances);
              </h6>
            </div>
            <div className="ppcont-wrapper-cdata-headingdata-bulletpoints">
              <span>●</span>
              <h6>
                {" "}
                <strong>Restrict</strong> certain processing of your data
                (direct marketing and/or "unsubscribe")
              </h6>
            </div>
            <p>
              Learn more about these rights, and find out how you can exercise
              your rights by sending us a message.
            </p>
          </div>

          <div className="ppcont-wrapper-cdata-headingdata">
            <h4>10. Changes</h4>
            <p>
              Changes to this privacy policy may be made by us from time to
              time. In no event will these changes result in the degradation of
              any security measures designed to protect you from unauthorized
              disclosure.
            </p>
          </div>
          <div className="ppcont-wrapper-cdata-headingdata">
            <h4>11. Contact Us</h4>
            <p>
              To send us your questions, comments, or complaints or receiving
              communications from us kindly email us using
              <strong>contact@worldofsports-nft.com</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
