import React, { useRef } from "react";
import Button from "./Button/button";
import Carousel from "react-multi-carousel";
import arrowRight from "../assets/images/arrowright.svg";
import arrowLeft from "../assets/images/arrowleft.svg";
import teamone from "../assets/images/Hans.png";
import teamtwo from "../assets/images/Peter.png";
import teamthree from "../assets/images/Dom.png";
import teamfour from "../assets/images/Sandro.png";
import teamfive from "../assets/images/Toni.png";
import teamSix from "../assets/images/Kerim.png";
import twitter from "../assets/images/twitterteam.svg";
import discord from "../assets/images/discordteam.svg";
const OurTeam = () => {
  const carouselnewRef = useRef(null);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1440 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1300, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 428 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="ourteamcontainer">
      <div className="ourteamcontainer-wrapper">
        <div className="ourteamcontainer-wrapper-btndiv">
          <Button title="Mint Now" />
        </div>

        <div className="ourteamcontainer-wrapper-teamdiv">
          <div className="ourteamcontainer-wrapper-teamdiv-parentdiv">
            <div className="ourteamcontainer-wrapper-teamdiv-parentdiv-heading">
              <h3>Our</h3>
              <h1>team</h1>
            </div>
            <p>
              This great team of 6 friends has it all! Former soccer
              professionals, creative minds, excellent network in the sports
              sector, entrepreneurs and investors enter the world of sports
              NFTs. We will work hard for the success of our vision to our
              like-minded people interested in sports. We love soccer, we love
              sports, we are WORLDOFSPORTS.NFT !!
            </p>
          </div>

          <div className="ourteamcontainer-wrapper-teamsliderdiv">
            <img
              className="ourteamcontainer-wrapper-teamsliderdiv-imgLeft"
              onClick={() => carouselnewRef.current.previous()}
              src={arrowLeft}
              alt="arrowleft"
            />
            <div className="ourteamcontainer-wrapper-teamsliderdiv-parent">
              <Carousel
                ref={carouselnewRef}
                infinite
                arrows={false}
                responsive={responsive}
              >
                <div className="ourteamcontainer-wrapper-teamsliderdiv-parent-siderOne">
                  <img className="teamsliderimg" src={teamone} alt="teamone" />
                  <h1>Hans</h1>
                  <h2>Founder</h2>
                  <div className="ourteamcontainer-wrapper-teamsliderdiv-parent-siderOne-icons">
                    <img src={twitter} alt="twitter" />
                    <img src={discord} alt="discord" />
                  </div>
                </div>
                <div className="ourteamcontainer-wrapper-teamsliderdiv-parent-siderOne">
                  <img className="teamsliderimg" src={teamtwo} alt="teamtwo" />
                  <h1>Peter</h1>
                  <h2>Founder</h2>
                  <div className="ourteamcontainer-wrapper-teamsliderdiv-parent-siderOne-icons">
                    <img src={twitter} alt="twitter" />
                    <img src={discord} alt="discord" />
                  </div>
                </div>
                <div className="ourteamcontainer-wrapper-teamsliderdiv-parent-siderOne">
                  <img className="teamsliderimg" src={teamthree} alt="teamthree" />
                  <h1>Dom</h1>
                  <h2>Founder</h2>
                  <div className="ourteamcontainer-wrapper-teamsliderdiv-parent-siderOne-icons">
                    <img src={twitter} alt="twitter" />
                    <img src={discord} alt="discord" />
                  </div>
                </div>
                <div className="ourteamcontainer-wrapper-teamsliderdiv-parent-siderOne">
                  <img className="teamsliderimg" src={teamfour} alt="teamfour" />
                  <h1>Sandro</h1>
                  <h2>Founder</h2>
                  <div className="ourteamcontainer-wrapper-teamsliderdiv-parent-siderOne-icons">
                    <img src={twitter} alt="twitter" />
                    <img src={discord} alt="discord" />
                  </div>
                </div>
                <div className="ourteamcontainer-wrapper-teamsliderdiv-parent-siderOne">
                  <img className="teamsliderimg" src={teamfive} alt="teamfour" />
                  <h1>Toni</h1>
                  <h2>Founder</h2>
                  <div className="ourteamcontainer-wrapper-teamsliderdiv-parent-siderOne-icons">
                    <img src={twitter} alt="twitter" />
                    <img src={discord} alt="discord" />
                  </div>
                </div>
                <div className="ourteamcontainer-wrapper-teamsliderdiv-parent-siderOne">
                  <img className="teamsliderimg" src={teamSix} alt="teamfour" />
                  <h1>Kerim</h1>
                  <h2>Founder</h2>
                  <div className="ourteamcontainer-wrapper-teamsliderdiv-parent-siderOne-icons">
                    <img src={twitter} alt="twitter" />
                    <img src={discord} alt="discord" />
                  </div>
                </div>
              </Carousel>
            </div>
            <img
              className="ourteamcontainer-wrapper-teamsliderdiv-imgRight"
              onClick={() => carouselnewRef.current.next()}
              src={arrowRight}
              alt="arrowright"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
