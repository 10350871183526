import React from "react";
import burger from "../assets/images/burger.svg";
import logo from "../assets/images/logo.svg";

const Header = () => {
  return (
    <div className="headercontainer">
      <div className="headercontainer-wrapper">
        <div className="headercontainer-wrapper-burgericon">
          <img src={burger} alt="burgericon" />
        </div>

        <div className="headercontainer-wrapper-logodiv">
          <img src={logo} alt="logo" />
        </div>

        <div className="headercontainer-wrapper-emptydiv">

        </div>
      </div>
    </div>
  );
};

export default Header;
