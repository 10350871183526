import React, { useState } from "react";
import { accordianData } from "../utils/helperdata";
import Accordian from "./Accordian";
import logo from "../assets/images/logo.svg"
import twitter from "../assets/images/twitterteam.svg"
import insta from "../assets/images/insta.svg"
import opensea from "../assets/images/opensea.svg"
import discord from "../assets/images/discordteam.svg"
import rightArrow from "../assets/images/rightArrow.svg"

const Faq = () => {
  const [show, setShow] = useState(1);
  return (
    <div className="faqcontainer">
      <div className="faqcontainer-wrapper">
        <div className="faqcontainer-wrapper-heading">
          <h3>Frequently</h3>
          <h1>Asked Questions</h1>
        </div>

        <div className="faqcontainer-wrapper-accord">
          {accordianData?.map((item) => (
            <Accordian
              show={show}
              setShow={setShow}
              data={item}
              onClick={() => setShow(item)}
            />
          ))}
        </div>

        <div className="faqcontainer-wrapper-footer">
          <div className="faqcontainer-wrapper-footer-imgdiv">
            <img src={logo} alt="logo" />
          </div>
          <div className="faqcontainer-wrapper-footer-copyright">
            <span>Copyright © 2022 Worldofsports.nft. All Rights Reserved.</span>
            <div className="faqcontainer-wrapper-footer-copyright-heading">
              <h2 onClick={() => window.open("/disclaimer")}>Disclaimer</h2>
              <h2 onClick={() => window.open("/privacypolicy")}>Privacy Policy</h2>
              <h2 onClick={() => window.open("/t&c")}>Terms and Conditions</h2>
            </div>
          </div>
          <div className="faqcontainer-wrapper-footer-followus">
            <h3>Follow Us On</h3>
            <img src={rightArrow} alt="rightArrow" />
          </div>

          <div className="faqcontainer-wrapper-footer-icons">
            <img onClick={() => window.open("https://www.instagram.com/worldofsports.nft/")} src={insta} alt="insta" />
            <img onClick={() => window.open("https://twitter.com/Wofsports_nft")} src={twitter} alt="twitter" />
            <img onClick={() => window.open("https://discord.gg/kguzv4TtTr")}  src={opensea} alt="opensea" />
            <img src={discord} alt="discord" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
