import React from "react";
import rightImg from "../assets/images/missionImg.png";
import utilitiesImg from "../assets/images/utilitiesimg.png";
import dot from "../assets/images/dot.svg";
import { utilitiesData } from "../utils/helperdata";
const Mission = () => {
  return (
    <div className="missioncontianer">
      <div className="missioncontianer-wrapper">
        <div className="missioncontianer-wrapper-missiondiv">
          <div className="missioncontianer-wrapper-missiondiv-leftside">
            <h3>About us</h3>
            <h1>World of Sports Mission</h1>
            <p>
              World of Sports NFT collection are 5555 unique and hand drawn NFTs
              and stand for absolute legendary superstars in the field of
              sports. We created 10 legend base characters from the world of
              soccer. This resulted in 4000 amazing and UNIQUE NFTs.
            </p>
            <p>
              In Web3 there is no WORLD OF SPORTS NFT. To avoid competition, we
              come up with a utility that makes us completely UNIQUE in „Sports
              Apes Derivates“. An absolute special are our 5 realistic art hand
              drawn celebrities in the outfits of their favorite sport clubs.
              Stay tuned for these 1555 ULTRA RARE NFTs. It's going to be
              awesome! In addition to supporting youth sport and providing
              sporting opportunities to those that would not have access to it,
              WORLD OF SPORTS holders will receive additional IRL benefits such
              as VIP seating at games, a chance to meet real-life soccer
              players, exclusive merch drops, access to weekly contests,
              visiting our charity parties and the opportunity to own limited
              edition soccer kits! We will build a long-term collection that
              DOMINATES the sporting niche of NFTs. We start by building a
              community of Soccer/Sports lovers. The original series of NFTs
              will be ‘The Genesis Series’. Anyone who owns a Genesis WORLD OF
              SPORTS NFT will have access to mint our future NFT collections.
            </p>
          </div>
          <div
            data-aos="flip-left"
            className="missioncontianer-wrapper-missiondiv-rightside"
          >
            <img src={rightImg} alt="" />
          </div>
        </div>

        <div className="missioncontianer-wrapper-utilitiesdiv">
          <div className="missioncontianer-wrapper-utilitiesdiv-imgdiv">
            <img src={utilitiesImg} alt="utilitiesImg" />
          </div>

          <div className="missioncontianer-wrapper-utilitiesdiv-content">
            <h1>Utility for Holders</h1>
            {utilitiesData?.map((item, index) => (
              <div
                key={index}
                className="missioncontianer-wrapper-utilitiesdiv-content-data"
              >
                <img src={dot} alt="dot" />
                <span>{item?.content}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mission;
