import React from "react";
import rightArrow from "../assets/images/rightArrow.svg"
import Button from "./Button/button";
import twitter from "../assets/images/twitterteam.svg"
import insta from "../assets/images/insta.svg"
import opensea from "../assets/images/opensea.svg"
import discord from "../assets/images/discordteam.svg"

const Hero = () => {
  return (
    <div   className="herocontainer">
      <div className="herocontainer-wrapper">
        <div data-aos="fade-up" className="herocontainer-wrapper-headings">
          <h3>Get the best NFT From</h3>
          <h1>World of Sports</h1>
          <p>
            Introducing World of Sports NFT, the first-ever NFT collection to
            support youth sports and provide both – in real life and digital
            sport experiences for their community. We have created UNIQUE and
            HAND DRAWN NFT-series collection for our community. Ultra Rare
            traits generated{" "}
          </p>

          <div className="herocontainer-wrapper-headings-btndiv">
            <Button title="Mint Now" />
          </div>
        </div>
        <div  className="herocontainer-wrapper-followus">
          <div data-aos="fade-up" className="herocontainer-wrapper-followus-heading">
            <h6>Follow us on</h6>
            <img src={rightArrow} alt="arrow" />
          </div>

          <div data-aos="fade-up" className="herocontainer-wrapper-followus-icons">
                <img onClick={() => window.open("https://www.instagram.com/worldofsports.nft/")} src={insta} alt="insta" />
                <img onClick={() => window.open("https://twitter.com/Wofsports_nft")} src={twitter} alt="twitter" />
                <img src={opensea} alt="opensea" />
                <img onClick={() => window.open("https://discord.gg/kguzv4TtTr")} src={discord} alt="discord" /> 
            </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
