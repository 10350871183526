import React from "react";

const Disclaimer = () => {

  return (
    <div className="disclaimercont">
      <div className="disclaimercont-wrapper">
        <div className="disclaimercont-wrapper-topheading">
          <h1>WORLDOFSPORTS DISCLAIMER</h1>
        </div>

        <div className="disclaimercont-wrapper-cdata">

          <div className="disclaimercont-wrapper-cdata-headingdata">
            <h4>1. No Investment Advice</h4>
            <p>
              The Worldofsports.NFT is very committed to the NFT project and hope to continue developing
              the Worldofsports.NFT community. While we aim to accomplish the goal of every creator,
              sometimes we or the Cryptocurrency network might encounter some unforeseen challenges, you
              accept that Worldofsports.NFT cannot make any guarantees of the interoperability of any digital
              asset, nor can we guarantee the sell ability of any token or NFT. By using and accessing the
              Worldofsports.NFT site, you agree and acknowledge that the use of blockchain Cryptocurrency
              is associated with a high degree of risk.

            </p>

         
          </div>
          <div className="disclaimercont-wrapper-cdata-headingdata">
            <h4>2. Warranty Disclaimer</h4>
            <p>
            Worldofsports.NFT disclaims all other warranties or conditions, express or implied of any kind,
including, without limitation, implied warranties or conditions of merchantability, fitness for a
particular purpose, title, and non-infringement as to the Worldofsports.NFT website, digital
asset contained therein. You agree that the Worldofsports.NFT are created unique to represent
each soccer player legendary and name. Your continuous use of the NFT or services mean you
agree that the Worldofsports.NFT do not own the rights to any soccer player name or outfits,
used in relation to each NFT, and we also disclaim all other warranties not related to the provided
NFT. 

            </p>
            <p>
            You also accept the inherent security risks of using the site and nothing is intended to be an offer
of security in any jurisdiction or constitute an offer or invitation for financial advice.
The Worldofsports.NFT will not be responsible or liable to you for any User error such as
forgotten or loss of private keys, incorrectly constructed transactions, or mistyped addresses;

            </p>
            <div className="disclaimercont-wrapper-cdata-headingdata-bulletpoints">
              <span>I.</span>
              <h6>
                Server failure or data loss;
              </h6>
            </div>
            <div className="disclaimercont-wrapper-cdata-headingdata-bulletpoints">
              <span>II.</span>
              <h6>
              Corrupted wallet files;
              </h6>
            </div>
            <div className="disclaimercont-wrapper-cdata-headingdata-bulletpoints">
              <span>III.</span>
              <h6>
              Unauthorized access to applications; 

              </h6>
            </div>
            <div className="disclaimercont-wrapper-cdata-headingdata-bulletpoints">
              <span>IV.</span>
              <h6>
              Any unauthorized third-party activities, including without limitation the use of viruses,
phishing, brute force, or other means of attack against the website. 
              </h6>
            </div>
           
          </div>
          <div className="disclaimercont-wrapper-cdata-headingdata">
            <h4>3. Price Volatility</h4>
            <p>
            NFT asset (i.e. Worldofsports.NFT) prices are highly volatile. Meaning there shall exist
Fluctuations in the price of digital assets which could materially and adversely affect the value of
your digital asset (price volatility). We cannot guarantee that your digital asset will not be lost.
Please do your own research. 
            </p>
            
          </div>
       
          
          
          
        

          

          
        </div>
      </div>
    </div>
  );
};

export default Disclaimer;
