import React from 'react'
import centerImg from "../assets/images/centerimgplayer.png"


export const ChoosePlayer = () => {
  return (
    <div className='cpcontainer'>
        <div className='cpcontainer-wrapper'>
            <div className='cpcontainer-wrapper-content'>
            <h1>Choose your star player</h1>
            <img src={centerImg} alt="centerImg" />
            </div>
        </div>
    </div>
  )
}
