import React from 'react'
import { ChoosePlayer } from '../components/ChoosePlayer'
import Faq from '../components/Faq'
import Hero from '../components/Hero'
import Mission from '../components/Mission'
import OurTeam from '../components/OurTeam'
import OurTen from '../components/OurTen'
import RoadMap from '../components/RoadMap'
import Header from '../layout/Header'

const Home = () => {
  return (
    <div style={{backgroundColor: "#1a1a1a"}}>
    <Header/>
    <Hero/>
     <OurTen />
    <Mission/>
    <RoadMap/>
    <ChoosePlayer/>
    <OurTeam/>
   <Faq/> 
    </div>
  )
}

export default Home