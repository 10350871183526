import React from 'react'

const Button = (props) => {
    const {title} = props;
  return (
    <div {...props} className='btncontainer'>
        <h5>{title}</h5>
    </div>
  )
}

export default Button