import React from "react";
import roadmapimg from "../assets/images/roadmap.png";
import { benefitsData } from "../utils/helperdata";

const RoadMap = () => {
  return (
    <div className="roadmapcontainer">
      <div className="roadmapcontainer-wrapper">
        <div data-aos="flip-up" className="roadmapcontainer-wrapper-heading">
          <h3>Our</h3>
          <h1>Roadmap</h1>
          <p>We Grow with our community until victory</p>
        </div>
        <div className="roadmapcontainer-wrapper-roadmapdiv">
          <div className="roadmapcontainer-wrapper-roadmapdiv-leftside">
            <div className="roadmapcontainer-wrapper-roadmapdiv-leftside-nodeone">
              <h2>11,11%</h2>
              <hr size="6" color="#F7941E" />
              <p>locked money for investments</p>
            </div>
            <div className="roadmapcontainer-wrapper-roadmapdiv-leftside-nodetwo">
              <h2>44,44 %</h2>
              <hr size="6" color="#F7941E" />
              <p>
                Live Talk with professional soccer player {"&"} presenting his
                NFT on WORLD OF SPORTS{" "}
              </p>
            </div>
            <div className="roadmapcontainer-wrapper-roadmapdiv-leftside-nodethree">
              <h2>88,88 %</h2>
              <hr size="6" color="#F7941E" />
              <p>
              VIP SOCCER Tickets Giveaway | Start Building A Trading Card Portal 
              </p>
            </div>
          </div>
          <div className="roadmapcontainer-wrapper-roadmapdiv-imgdiv">
            <img src={roadmapimg} alt="roadmapimg" />
          </div>

          <div className="roadmapcontainer-wrapper-roadmapdiv-rightside">
            <div className="roadmapcontainer-wrapper-roadmapdiv-rightside-nodeone">
              <h2>22,22 %</h2>
              <hr size="6" color="#F7941E" width="100%" />
              <p>5:0 home win | 5 APE legends will be sent to random holders</p>
            </div>
            <div className="roadmapcontainer-wrapper-roadmapdiv-rightside-nodetwo">
              <h2>66,66 %</h2>
              <hr size="6" color="#F7941E" width="100%" />
              <p>
                Merch-Store opening | holder reflections with shirts, hoodies of
                own NFT
              </p>
            </div>
            <div className="roadmapcontainer-wrapper-roadmapdiv-rightside-nodethree">
              <h2>100 %</h2>
              <hr size="6" color="#F7941E" width="100%" />
              <p>
                community driven sponsoring of youth soccer players or soccer
                teams
              </p>
            </div>
          </div>
        </div>

        <div className="roadmapcontainer-wrapper-benefits">
            <div className="roadmapcontainer-wrapper-benefits-content">
                <h1>How will this collection benefit its holder's lives?</h1>
               
                {benefitsData?.map((item,index) => (
                    <div key={index} className="roadmapcontainer-wrapper-benefits-content-data">
                    <span>{item.id}.</span>
                    <p>{item.content}</p>
                </div>
                ))}
               
            </div>
        </div>
      </div>
    </div>
  );
};

export default RoadMap;
