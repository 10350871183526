import React from "react";

const TermAndCondtion = () => {
  const ppData = [
    "Introduction of the Terms",
    "The Worldofsports NFT Collections",
    "Eligibility",
    "No Representation",
    "User Licensed Rights",
    "Copyright Policy",
    "Restriction",
    "Important Warnings",
    "Warranty Disclaimer",
    "Limitation Of Liability",
    "GDPR Compliance",
    "Amendment or Update",
    "Governing Law",
    "Supports",
  ];
  return (
    <div className="ppcont">
      <div className="ppcont-wrapper">
        <div className="ppcont-wrapper-topheading">
          <h1>THE WORLDOFSPORTS.NFT TERMS AND CONDITIONS</h1>
        </div>
        <div className="ppcont-wrapper-contentparent">
          <div className="ppcont-wrapper-contentparent-headingdiv">
            <h2>Table of Content</h2>
          </div>
          <div className="ppcont-wrapper-contentparent-contentdiv">
            {ppData?.map((item, index) => (
              <div className="ppcont-wrapper-contentparent-contentdiv-mapdata">
                <span>{index + 1 + ")"}</span>
                <h6>{item}</h6>
              </div>
            ))}
          </div>
        </div>
        <div className="ppcont-wrapper-cdata">
          <div className="ppcont-wrapper-cdata-heading">
            <h5>PLEASE READ CAREFULLY</h5>
          </div>
          <div className="ppcont-wrapper-cdata-headingdata">
            <h4>1. Introduction of the Terms</h4>
            <p>
              The <strong>Worldofsports.NFT</strong> is owned and operated by{" "}
              <strong>Worldofsports</strong> accessible at
              <strong> https://www.worldofsports-nft.com</strong>.{" "}
              <strong>(“The Worldofsports NFT</strong>,” “we,” “us” or “our”)
              and these Terms and Conditions (these <strong>“Agreement”</strong>
              ) govern your access to, participation and use of the
              Worldofsports NFT and website and any online services, provided on
              or in connection with the Worldofsports NFT (collectively, the{" "}
              <strong>“Service”</strong>). Detailed rules regarding the Pre Sale
              process are available on the site
            </p>
          </div>
          <div className="ppcont-wrapper-cdata-headingdata">
            <h4>2. The Worldofsports NFT Collections</h4>
            <p>
              <strong>The Worldofsports</strong> NFT site is an online platform
              that offers a unique NFT collection via the Ethereum Network
              inspired by the legends of the game of Soccer. The NFT pre-sale
              will generate 1000 traits <strong>Worldofsports</strong>{" "}
              Collections that correlate to soccer celebrities and legends
              hosted on Ethereum block-chain Network. The{" "}
              <strong>Worldofsports NFT</strong> was created to help individuals
              connect with their favorite football club, stars and Mint on the
              go. Users who hold the <strong>Worldofsports NFT</strong> during
              pre-sale shall receive a discount when we release our Merch.
            </p>

            <p>
              Your continuous use of the site and NFT means, you agree that
              <strong>Worldofsports</strong> is not a broker, financial
              institution, or credit institution, we are providing these NFT
              collections to give back to our community. Due to the
              decentralized nature of our collections, at no stage does the
              <strong>Worldofsports NFT</strong> hold or possess any assets on
              behalf of the holders. To own the Worldofsports NFT, you agree to
              comply with all the agreements in this terms.
            </p>
            <p>
              <strong>
                By clicking on the “Join” button, or visiting the website, or
                participating in the pre-sale, you are agreeing to be legally
                bound by all of the terms and conditions of this Agreement. You
                agree to comply with the terms of this Agreement. If you do not
                agree with the terms of this Agreement, please do not
                participate or interact with the Collections in any way.{" "}
              </strong>
            </p>

            <p>
              <strong>
                If you are under the age of 18 but at least 13 years of age, you
                may participate in the NFT event only with the permission of,
                and under the supervision of, a parent or legal guardian.
              </strong>
            </p>
          </div>
          <div className="ppcont-wrapper-cdata-headingdata">
            <h4>3. Eligibility</h4>
            <p>
              The <strong>Worldofsports NFT</strong> is open only to individuals
              who are 18 years and above and have the right and authority to
              enter into this Agreement, and are fully able and competent to
              satisfy the terms, conditions, and obligations herein.
            </p>
          </div>
          <div className="ppcont-wrapper-cdata-headingdata">
            <h4>4. Owning a Worldofsports NFT</h4>
            <p>
              The <strong>Worldofsports</strong> NFT will be available in two
              stages, a whitelisted presale and a public sale. You must own a
              Metamask Wallet to join and own the{" "}
              <strong>Worldofsports NFT</strong>. These Metamask Wallet enable
              you to hold, store, and join the
              <strong>Worldofsports NFT</strong> presale and whitelist through
              Ethereum Network. When you own the{" "}
              <strong>Worldofsports NFT</strong> using your Metamask Wallet, you
              understand and agree that;
            </p>
            <div className="ppcont-wrapper-cdata-headingdata-bulletpoints">
              <span>●</span>
              <h6>
                You will be redirected to a third-party Network, where your NFT
                Ownership will be completed and the volume of the{" "}
                <strong>Worldofsports</strong>
                NFT will be deposited into your Metamask Wallet;
              </h6>
            </div>
            <div className="ppcont-wrapper-cdata-headingdata-bulletpoints">
              <span>●</span>
              <h6>
                You are solely responsible for maintaining the security of your
                Metamask Wallet and your control over any related authentication
                transactions, private or public cryptocurrency keys, NFTs or
                cryptocurrencies that are stored in or are accessible through
                your Metamask Wallet;
              </h6>
            </div>
            <div className="ppcont-wrapper-cdata-headingdata-bulletpoints">
              <span>●</span>
              <h6>
                Any unauthorized access to your Metamask Wallet by third parties
                could result in the loss or theft of your{" "}
                <strong>The Worldofsports Collections</strong>;
              </h6>
            </div>
            <div className="ppcont-wrapper-cdata-headingdata-bulletpoints">
              <span>●</span>
              <h6>
                <strong>The Worldofsports NFT</strong> is not responsible for
                maintaining the security of your Metamask Wallet;
              </h6>
            </div>
            <div className="ppcont-wrapper-cdata-headingdata-bulletpoints">
              <span>●</span>
              <h6>
                <strong>The Worldofsports NFT</strong> has no responsibility or
                liability to you for any unauthorized access to or use of your
                Metamask Wallet or if you are unable to locate your credentials;
              </h6>
            </div>
            <p>
              If you notice any unauthorized or suspicious activity in your
              Metamask Wallet please notify our support channel immediately.
            </p>
          </div>
          <div className="ppcont-wrapper-cdata-headingdata">
            <h4>5. No Representation</h4>
            <p>
              You agree to be responsible for verifying the identity,
              legitimacy, and authenticity of the Collection you own on the
              site. These terms and conditions are important and affect your
              legal rights, so please read them carefully. We want to let you
              know that the terms include user license rights which will, with
              limited exceptions, require you to be bound by them.
            </p>

            <p>
              By clicking on the “Join” button, participating in the Pre-sale
              process, using our site, and/or joining the Worldofsports NFT
              Whitelist, you agree to be bound by these terms and all of the
              terms incorporated herein by reference. If you do not agree to
              these terms, you may not access or use the service or own the
              crypto assets.
            </p>
          </div>
          <div className="ppcont-wrapper-cdata-headingdata">
            <h4>6. User Licensed Right</h4>
            <p>
              If you join the Worldofsports NFT whitelist, and Mint on the
              pre-sale, you own the NFT and the licensed right attached to each
              NFT. Subject to the legal jurisdiction in which you abide, the
              Worldofsports NFT grants you a worldwide, royalty-free license to
              personal and non-commercial use, copy, and display the owned
              collectibles, along with any extensions that you choose to create
              or use, solely for your own personal, non-commercial use.
            </p>
          </div>
          <div className="ppcont-wrapper-cdata-headingdata">
            <h4>7. Copyright Policy</h4>
            <p>
              The Website contains valuable trademarks and service marks owned
              and used by the <strong>Worldofsports</strong>, including but not
              limited to, name, pictures, logo, videos, the Worldofsports NFT
              design, and (collectively, the " Worldofsports NFT"). Any use of
              the site Marks without the Worldofsports prior written permission
              is strictly prohibited. Copyright(c) 2022(s) If you believe that
              any content or other material made available on the site,
              infringes upon any NFT copyright design you own, and that you
              control, you may notify us in writing.
            </p>
          </div>
          <div className="ppcont-wrapper-cdata-headingdata">
            <h4>8. Restriction</h4>
            <p>
              Owning a Worldofsports collectible NFT does not grant you the
              rights to duplicate, steal, remake, or redo any of the ideas
              associated with the Worldofsports NFT. You are not permitted to
              separate any rights from your NFT. If you sell ownership of your
              NFT then you also transfer all associated rights and restrictions
              described herein.
            </p>
          </div>

          <div className="ppcont-wrapper-cdata-headingdata">
            <h4>9. Important Warnings</h4>
            <p>
              Participating in the Worldofsports NFT pre-sale might be a risky
              decision, and therefore it is only aimed at experienced users’ who
              are used to blockchain technology and NFTs especially. By
              accepting this Contract, You acknowledge (i) having the necessary
              knowledge and understanding of the blockchain technology and the
              Worldofsports NFTs, and (ii) understanding the risks associated
              with blockchain-based software systems and Worldofsports NFTs as
              described hereof.
            </p>
          </div>

          <div className="ppcont-wrapper-cdata-headingdata">
            <h4>10. Warranty Disclaimer</h4>
            <p>
              Your use of the site, and Worldofsports NFT is at your sole risk.
              except as expressly provided to the contrary in a writing by
              Worldofsports. The site and any Worldofsports NFT are provided on
              an “as is” and “as available” basis without warranties or
              conditions of any kind, either express or implied.
            </p>
            <p>
              The Worldofsports collectibles are intangible digital assets. They
              exist only by the ownership record maintained in the Ethereum
              network. Any transfer of title that might occur in any unique
              digital asset occurs on the decentralized ledger within the
              Ethereum Network platform.
            </p>
          </div>
          <div className="ppcont-wrapper-cdata-headingdata">
            <h4>11. Limitation Of Liability</h4>
            <p>
              As the law permits us, in no event will the Worldofsports NFT or
              its licensors be liable to you or any third party for any loss of
              profit or any indirect, consequential, exemplary, incidental,
              special, or punitive damages arising from these terms, the site,
              or the Worldofsports NFT? Access to, and use of, the site, the
              Worldofsports NFT are at your discretion and risk, and you will be
              solely responsible for any damage to you, your computer system or
              mobile device, or loss of data resulting therefrom.
            </p>
          </div>
          <div className="ppcont-wrapper-cdata-headingdata">
            <h4>12. GDPR Compliance</h4>
            <p>
              <strong>
                GDPR: The Services offered herein are not directed to teenagers
                but to adults who are over the age of 18. You must be 18 years
                of age or older to use the site and mint the Worldofsports NFT.
                If you are a user within and outside the European Union and
                European Economic Area, you agree to comply with all terms
                described herein and agree that we comply with the European
                Union GDPR Regulation and does not permit access by, and will
                not knowingly permit access of third-party to user identifiable
                information.
              </strong>
            </p>
          </div>
          <div className="ppcont-wrapper-cdata-headingdata">
            <h4>13. Amendment or Update</h4>
            <p>
              At its sole discretion and without prior notice, The Worldofsports
              NFT may amend, change, update or add to the current Terms and
              Conditions. In this event, You will receive a notification and
              have the right (i) to accept the update or (ii) to stop minting
              The Worldofsports collection. If You continue to participate, you
              are deemed to have accepted the amendment.
            </p>
          </div>
          <div className="ppcont-wrapper-cdata-headingdata">
            <h4>14. Miscellaneous</h4>
            <p>
              These Terms and Conditions shall constitute the entire agreement
              between The Worldofsports NFT and you and also govern your use of
              the site, including the Worldofsports NFT. Except as expressed
              provided herein, these agreements are intended solely for the
              benefit of the parties and are not intended to confer third-party
              beneficiary rights upon any other person or entity.
            </p>
            <p>
              The section titles in these Terms have no legal or contractual
              effect but are for convenience only. We will provide Notices to
              you via email Or provide notices to you of changes to these Terms
              or other features by displaying notice changes on the specific
              page.
            </p>
          </div>
          <div className="ppcont-wrapper-cdata-headingdata">
            <h4>15. Governing Law</h4>
            <p>
              These terms and the relationship between you and Worldofsports NFT
              is governed by the laws of the The Germany Federal Court of
              Justice (Bundesgerichtshof – BGH ). If you think the laws fail to
              satisfy the law of your jurisdiction, we should like to hear from
              you. However, ultimately it is your choice as to whether you wish
              to use our website.
            </p>
          </div>
          <div className="ppcont-wrapper-cdata-headingdata">
            <h4>16. Supports</h4>
            <p>
              If you have any questions or comments about the NFTs site or these
              Terms please contact our support team at
              contact@worldofsports-nft.com
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermAndCondtion;
