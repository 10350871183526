import React,{useRef} from "react";
import Carousel from "react-multi-carousel";
import arrowRight from "../assets/images/arrowright.svg";
import arrowLeft from "../assets/images/arrowleft.svg";
import imgOne from "../assets/images/wajji.png";
import imgTwo from "../assets/images/wajji1.png";
import imgThree from "../assets/images/wajji2.png"
import rooney from "../assets/images/rooney.png"
import zidane from "../assets/images/Zidane.png"
import valderrama from "../assets/images/Valderrama.png"
import ronaldo from "../assets/images/ronaldo.png"
import pele from "../assets/images/PeleApe.png"
import cantonaPreview from "../assets/images/CantonaPreview.png"
import backham from "../assets/images/beckham.png"

const OurTen = () => {
  const carouselnewRef = useRef(null);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="ourtencontainer">
      <div className="ourtencontainer-wrapper">
        <div className="ourtencontainer-wrapper-headings">
          <h3>Our 10</h3>
          <h1>APE legends are:</h1>
        </div>

        <div className="ourtencontainer-wrapper-sliderdiv">
          <img className="ourtencontainer-wrapper-sliderdiv-imgLeft" onClick={() => carouselnewRef.current.previous()} src={arrowLeft} alt="arrowleft" />
          <div className="sliderdiv" >
          <Carousel ref={carouselnewRef}  infinite
              arrows={false}  responsive={responsive}>
           
           <div className="slideone">

              <div className="slideone-imgdiv">
              <img className="slideone-imgdiv-imgOne" src={imgOne} alt="imgOne" />
              </div>

              <h1>Diego APEdona</h1>
           </div>
           
           <div className="slideone">

              <div className="slideone-imgdiv">
              <img className="slideone-imgdiv-imgThree" src={imgThree} alt="imgTwo" />
              </div>

              <h1>RonaldAPeiho</h1>
           </div>

           <div className="slideone">

              <div className="slideone-imgdiv">
              <img className="slideone-imgdiv-imgTwo" src={imgTwo} alt="imgThree" />
              </div>

              <h1>NeymAPE</h1>
           </div>

           <div className="slideone">

              <div className="slideone-imgdiv">
              <img className="slideone-imgdiv-imgThree" src={cantonaPreview} alt="cantonaPreview" />
              </div>

              <h1>Eric CantonAPE</h1>
           </div>

           <div className="slideone">

              <div className="slideone-imgdiv">
              <img className="slideone-imgdiv-imgThree" src={rooney} alt="rooney" />
              </div>

              <h1>Wayne Roon APE</h1>
           </div>

           <div className="slideone">

              <div className="slideone-imgdiv">
              <img className="slideone-imgdiv-imgThree" src={valderrama} alt="valderrama" />
              </div>

              <h1>Carlos ValerrAPEma</h1>
           </div>
          
           <div className="slideone">

              <div className="slideone-imgdiv">
              <img className="slideone-imgdiv-imgThree" src={zidane} alt="zidane" />
              </div>

              <h1>Zinedine ZidAPE</h1>
           </div>
          
           
           
          
           <div className="slideone">

              <div className="slideone-imgdiv">
              <img className="slideone-imgdiv-imgThree" src={ronaldo} alt="ronaldo" />
              </div>

              <h1>Ronaldo Ape 9</h1>
           </div>

           <div className="slideone">

              <div className="slideone-imgdiv">
              <img className="slideone-imgdiv-imgThree" src={backham} alt="backham" />
              </div>

              <h1>David BeckhAPE</h1>
           </div>
           <div className="slideone">

              <div className="slideone-imgdiv">
              <img className="slideone-imgdiv-imgThree" src={pele} alt="pele" />
              </div>

              <h1>PeleAPE</h1>
           </div>
           
          
           
         
          
           
          
          
         </Carousel>
          </div>
          <img className="ourtencontainer-wrapper-sliderdiv-imgRight" onClick={() => carouselnewRef.current.next()} src={arrowRight} alt="arrowright" />
        </div>
      </div>
    </div>
  );
};

export default OurTen;
